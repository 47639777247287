/* Overall slider container */
.home-slider {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

/* Swiper container */
.home-swiper {
  width: 100%;
  height: 100%;
}

/* Individual slide */
.home-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.home-slide-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Centered title */
.home-slide-title {
  font-size: 10vw; /* Dynamically scale text size */
  font-weight: bold;
  font-family: Vonique, serif;
  letter-spacing: 3px;
  color: #fff;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 1);
  z-index: 2;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  text-align: center; /* Ensure proper alignment on smaller screens */
}

/* Title animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Custom navigation button styles */
.custom-prev,
.custom-next {
  width: 50px;
  height: 50px;
  border: 2px dashed white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.3s ease, transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  opacity: 1; /* Fully visible by default */
  z-index: 10;
}

/* Positioning */
.custom-prev {
  left: 15px; /* Adjusted for smaller screens */
}

.custom-next {
  right: 15px; /* Adjusted for smaller screens */
}

/* Arrow inside the navigation buttons */
.custom-prev::before,
.custom-next::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  transform: rotate(225deg);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.custom-next::before {
  transform: rotate(45deg); /* Flip the arrow for the next button */
}

/* Disabled state for buttons */
.custom-prev.disabled,
.custom-next.disabled {
  opacity: 0.3; /* Reduced opacity to indicate disabled state */
  cursor: not-allowed; /* Change cursor to indicate non-clickable */
  pointer-events: none; /* Disable interactions */
}

/* Hover effect on enabled buttons */
.custom-prev:not(.disabled):hover,
.custom-next:not(.disabled):hover {
  transform: translateY(-50%) scale(1.2); /* Bigger zoom effect on hover */
  background-color: rgba(
    255,
    255,
    255,
    0.2
  ); /* Add a subtle background on hover */
}

.custom-prev:not(.disabled):hover::before {
  transform: rotate(270deg) scale(1.2); /* Keep pointing left, but grow the arrow */
  opacity: 0.9;
}

.custom-next:not(.disabled):hover::before {
  transform: rotate(90deg) scale(1.2); /* Keep pointing right, but grow the arrow */
  opacity: 0.9;
}

/* Cool button hover animation */
.custom-prev:not(.disabled):hover::after,
.custom-next:not(.disabled):hover::after {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  border: 2px dashed rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  animation: pulse 0.6s ease-out infinite;
}

/* Pulse animation for hover */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  /* Reduce button size and adjust positioning */
  .custom-prev,
  .custom-next {
    width: 40px;
    height: 40px;
    top: 60%; /* Move buttons lower on mobile for better access */
  }

  .custom-prev {
    left: 10px;
  }

  .custom-next {
    right: 10px;
  }

  .custom-prev::before,
  .custom-next::before {
    width: 10px;
    height: 10px;
    border-top: 1.5px solid white;
    border-right: 1.5px solid white;
  }

  /* Scale text size for smaller screens */
  .home-slide-title {
    font-size: 7vw; /* Dynamically smaller text for mobile */
    letter-spacing: 2px;
  }
}

@media (max-width: 480px) {
  .custom-prev,
  .custom-next {
    width: 35px;
    height: 35px;
  }

  .custom-prev::before,
  .custom-next::before {
    width: 8px;
    height: 8px;
  }

  .home-slide-title {
    font-size: 8vw;
    letter-spacing: 1.5px;
  }
}

/* Not Found Container */
.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f0f4f8;
  color: #333;
}

/* Face */
.face {
  position: relative;
  width: 150px;
  height: 150px;
  margin-bottom: 1rem;
  background-color: #0078ff;
  border-radius: 50%;
  animation: grow 3s infinite ease-in-out;
}

/* Eyes */
.eye {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  top: 40%;
}

.left-eye {
  left: 30px;
}

.right-eye {
  right: 30px;
}

/* Pupils */
.pupil {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #000;
  border-radius: 50%;
  top: 37%;
  left: 37%;
  transform: translate(-50%, -50%); /* Center the pupil */
  transition: transform 0.1s ease-out; /* Smooth pupil movement */
}

/* Blinking Animation */
.eye.blink {
  height: 5px;
  background-color: #0078ff; /* Match face color */
  transition: height 0.1s ease;
}

.eye.blink .pupil {
  display: none; /* Hide pupils when blinking */
}

/* Mouth */
.mouth {
  position: absolute;
  bottom: 25px;
  left: 45px;
  transform: translateX(-50%);
  width: 60px;
  height: 20px;
  background-color: #fff;
  transition: all 0.2s ease;
  border-radius: 50%; /* Default smile shape */
}

/* Text */
.error-title {
  font-size: 5rem;
  margin: 0;
  font-weight: bold;
}

.error-message {
  font-size: 1.2rem;
  margin: 1rem 0;
}

/* Animated Button */
.back-button {
  margin-top: 1.5rem;
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: #0078ff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  animation: float 2s infinite ease-in-out, pulse 1.5s infinite ease;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.back-button:hover {
  transform: scale(1.1);
  background-color: #005fcc;
}

/* Animations */

/* Face Growing Animation */
@keyframes grow {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

/* Button Floating Animation */
@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

/* Button Pulse Animation */
@keyframes pulse {
  0%,
  100% {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  50% {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  }
}
